import React, { Component } from "react"
import { graphql } from "gatsby"
import { DefaultLayout } from "../layouts"
import { Logo, Chat, About } from "../components"

export default class IndexPage extends Component {
  state = {
    showCV: false,
  }

  render() {
    const { showCV } = this.state

    return (
      <DefaultLayout title="RockingElevator">
        <Chat openCV={() => this.setState({ showCV: true })} />

        {showCV && (
          <About handleClose={() => this.setState({ showCV: false })} />
        )}
        <Logo
          handleClick={() => this.setState({ showCV: true })}
          color={showCV ? "#333333" : "#3D5CFF"}
          showBubble={!showCV}
        />
      </DefaultLayout>
    )
  }
}

// export const query = graphql`
//   query {
//     site {
//       siteMetadata {
//         title
//       }
//     }
//   }
// `;
